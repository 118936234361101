import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import axios from '../../../../axios/axios';
import './styles.css';
import Loading from "../../../screen/loading";

const OrdersHistory = ({ data, onClose }) => {
    const [orders, setOrders] = useState('');
    const [loading, setLoading] = useState(false);

    const load = useCallback(() => {
        setLoading(true);
        axios({
            url: `order/orderchangehistory`,
            params: { filterData: { orderId: data?.id }},
        })
        .then(response => {
            setOrders(response?.data?.data);
            setLoading(false);
        })
        .catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [data]);

    useEffect(() => {
        load();
    }, [load]);

    const getDataValue = (value, field) => {
        if (field === 'Due Date' || field === 'Ready Date') {
            return moment.utc(value).utcOffset(4).format('MM/DD/YY HH:mm');
        } else if (field === 'Temperature') {
            if (value === '13') {
                return 'Frozen';
            } else if (value === '2') {
                return 'Chilled';
            } else if (value === '3') {
                return 'Dry';
            } else if (value === '4') {
                return 'C|F';
            }
        } else {
            return value;
        }
    }

    return (
    <div className='order-history__container'>
        <div className='order-history-modal__header'>
            <p className='order-history-modal__header-text'>Changes</p>
            <div effect="material" className='button white mini close order-history__header-closeBtn' command="close" onClick={onClose}>
                <icon>close</icon>
            </div>
        </div>
        {loading ? <div className='loading-container'><Loading /></div> : null}
        {orders && orders.length ?
        <table className='order-history-modal__table'>
            <tr className='history-modal__tr'>
                <th className='history-modal__th'>Changed by</th>
                <th className='history-modal__th'>Date/Time</th>
                <th className='history-modal__th'>Field</th>
                <th className='history-modal__th'>Old Value</th>
                <th className='history-modal__th'>New Value</th>
            </tr>
            {orders?.map(order => (
                <tr className='history-modal__tr' key={order.id}>
                <td className='history-modal__td'>{order.updated}</td>
                <td className='history-modal__td'>{order.editDate ? moment.utc(order.editDate).utcOffset(4).format('MM/DD/YY HH:mm') : ''}</td>
                <td className='history-modal__td third-tr'>{order.field}</td>
                <td className='history-modal__td'>{order.fromData ? getDataValue(order.fromData, order.field) : ''}</td>
                <td className='history-modal__td'>{order.toData ? getDataValue(order.toData, order.field) : ''}</td>
                </tr>
            ))}
        </table> : null}
        {!orders.length && !loading && <div className='history-modal_no-data'>There are no changes for this order</div>}
    </div>)
};

export default OrdersHistory;