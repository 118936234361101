import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { message } from 'antd';
import { debounce } from 'lodash';
import Scroll from "../../scroll/scroll";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import { saveScreenSwap } from '../../../../store/actions/authentication/authentication';
import { load, unreactOrders, assignOrdersToNewLoad, addNewLoad, createSubLoadAssignOrders }
    from '../../../../store/actions/loadArea/loadArea';
import { openPopup, closePopup, closePopupAsync } from "../../../../store/actions/popup/popup";
import { PopupTypes } from "../../custom/popup/popup";
import { ElementTypes, ElementValidations, GenerateToolBars, ValueListUrls } from '../../../config';
import LocationConfig from '../../../screens/Location/config';
import Manifest from '../../../screens/Manifest/config';
import axios from '../../../../codeless/axios/axios';
import ToolBarButton from '../../../elements/toolBarButton';
import LumperModal from './LumperModal';
import AssignOrdersModalNewLoad from './AssignOrdersModalNewLoad';
import Search from './Search';

const ToolBar = props => {
    const {
        mainResource,
        saveScreenSwap,
        screens,
        state,
        unreactOrders,
        assignOrdersToNewLoad,
        createSubLoadAssignOrders,
        addNewLoad,
        openPopup,
        load,
        resource,
        userRole,
        filter,
        setOnlyTBDOrder,
        isBookingPage,
        setSearchInput,
        setFilterInput,
        searchInput,
    } = props;

    const [lumperModalIsOpen, setLumperModalIsOpen] = useState(false);
    const openModalNewLoad = state[resource].openModalNewLoad;
    const [assignOrdersModalIsOpen, setAssignOrdersModalIsOpen] = useState(false);
    const allAvailableOrders = resource === 'load' ? state?.load?.orders : state?.outboundload?.orders;

    useEffect(() => {
        setAssignOrdersModalIsOpen(openModalNewLoad);
    }, [openModalNewLoad]);

    const swap = screens && screens[mainResource] ? screens[mainResource].swap : false;
    const loadArea = state[resource];
    const selectedAvailableOrders =
        (loadArea && loadArea.regionsAvailableOrders) ?
            loadArea.regionsAvailableOrders.filter(
                o => o.rowState.type === 'order' && o.rowState.reacted) : [];
    const selectedAssignedOrders =
        (loadArea && loadArea.loadsAssignedOrders) ?
            loadArea.loadsAssignedOrders.filter(
                o => o.rowState.type === 'order' && o.rowState.reacted) : [];

    const availableSelection = (load && loadArea.orders) ?
        loadArea.orders.filter(
            o => selectedAvailableOrders.findIndex(ao =>
                ao.rowState.orderData.id === o.id) !== -1 ||
                selectedAssignedOrders.findIndex(ao =>
                    ao.rowState.orderData.id === o.id) !== -1) : [];
    const totalSpace = availableSelection.reduce((sum, o) => sum + o.space, 0);
    const totalPallet = availableSelection.reduce((sum, o) => sum + o.palletCount, 0);
    const totalWeight = availableSelection.reduce((sum, o) => sum + o.weight, 0);

    const success = (text) => {
        message.success(`${text}`);
    };

    const createManifest = () => {
        const windowKey = "Manifest";
        const config = Manifest;
        const title = `Edit ${config.Title}`;
        openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigSection,
            bodyProps: { windowKey },
            config: {
            Title: 'Create Manifest',
            SectionGroups: [
                {
                    Sections: [
                        {
                            Fields: {
                                week: {
                                    Title: 'Select Week',
                                    Field: ElementTypes.LabelField(),
                                }
                            }
                        }
                    ]
                }
            ],
            ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
        },
            data: {
                week: `${new Date().getWeek()}`,
                noteCheckBeforeSave:true,
            },
            onSave: (result, isClose) => {
                axios({
                    url: `/load/createmanifestforweek`,
                    method: 'put',
                    headers:
                    {
                        "Content-Type": "application/json-patch+json"
                    },
                    data: result.week,
                }).then((res) => {
                    success(res?.data?.message)
            
                })
                if (isClose) {
                    closePopupAsync(windowKey);
                }
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const createSabload = () => {
        const windowKey = "SubLoad";
        openPopup({
            windowKey,
            fullScreen: false,
            title: 'Create Sub Load',
            type: PopupTypes.ConfigSection,
            bodyProps: { windowKey },
            data: {
                dropLocationId: undefined
            },
            config: {
                Title: 'Select Destination',
                SectionGroups: [
                    {
                        Sections: [
                            {
                                Fields: {
                                    dropLocationId: {
                                        Title: 'DEL WH',
                                        Field: ElementTypes.Generic(ElementTypes.ComboAddWithCache, {
                                            dataSource: ValueListUrls.LocationDoor,
                                            addparams: {
                                                TitlePrefix: 'Drop',
                                                PopupConfig: LocationConfig,
                                                ValueField: 'defLocationDoorId'
                                            },
                                            selectedValueType: 'drop2WH'
                                        }),
                                        Validations: [ElementValidations.Required()]
                                    }
                                }
                            }
                        ]
                    }
                ],
                ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
            },
            onSave: (result, isClose) => {
                createSubLoadAssignOrders(resource, selectedAssignedOrders, result.dropLocationId);
                if (isClose) {
                    closePopupAsync(windowKey);
                }
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const trySaveScreenSwap = params => {
        document.getElementById('main').scrollLeft = 0;
        saveScreenSwap(params.mainResource, params.swap)
    };

    const tryAssignOrdersToNewLoad = params => resource === 'load' ? 
            assignOrdersToNewLoad(params.resource, params.orders, 0, state.load.orders) :
            assignOrdersToNewLoad(params.resource, params.orders, 0, state.outboundload.orders);

    const forAdminAndCustomerRoles = userRole === 'Admin' || userRole === 'Customer';
    const noLumper = selectedAssignedOrders?.filter(order => order.rowState.orderData?.dropWhLumper === 1).length > 0;

    const debounced = debounce(() => {
        message.warning("It's not possible to add a lumper for this WH", 1)
    }, 700);
    
    const handleOpenLumperModal = () => {
        if (noLumper) {
            debounced();
        } else {
            selectedAssignedOrders.length && setLumperModalIsOpen(true);
        }
    };

    const handleRefresh = () => {
        load(resource);
        setOnlyTBDOrder(false);
    }

    return (<toolbar 
                onMouseDown={(e)=> document.activeElement.blur()} 
                scroll="" 
                lightgrey="" 
                className={`subtoolbar ${filter ? 'withFilter' : ''}`}>        
        <Scroll>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <wrap style={{ marginLeft: '14px'}}>
                    <action>
                        {!isBookingPage && <ToolBarButton text="Swap Tables"
                            title='Swap'
                            onClick={trySaveScreenSwap}
                            clickParams={{ mainResource: mainResource, swap: !swap }}
                        />}
                        <ToolBarButton text="Refresh"
                            inProcess={loadArea.refreshInProcess}
                            title='Refresh Orders'
                            onClick={handleRefresh}
                            clickParams={resource}
                        />
                        <ToolBarButton 
                            primary
                            className="add-load"
                            text="Add Load"
                            inProcess={loadArea.addNewInProcess || loadArea.createInProcess || loadArea.createSubInProcess}
                            onClick={addNewLoad}
                            clickParams={resource} />
                        {loadArea.loadType === 'inbound' && forAdminAndCustomerRoles ?
                            <ToolBarButton text="Add Manifest"
                                title='Add Manifest'
                                onClick={createManifest} />
                            : null}
                        {loadArea.loadType === 'outbound' ?
                            <ToolBarButton 
                                primary 
                                text="Add Lumper"
                                className="add-load"
                                title='Add Lumper'
                                onClick={() => handleOpenLumperModal()} />
                            : null}
                        <Search
                            setSearchInput={setSearchInput}
                            setFilterInput={setFilterInput}
                            searchInput={searchInput}
                        />
                    </action>
                    <action right="">
                        {
                            availableSelection.length > 0 && (<>
                                {
                                    selectedAssignedOrders.filter(a => a.rowState.isSub || a.rowState.subId).length === 0 &&
                                    <ToolBarButton primary text="Create New Load"
                                        inProcess={loadArea.addNewInProcess || loadArea.createInProcess || loadArea.createSubInProcess}
                                        title='Create New Load'
                                        onClick={tryAssignOrdersToNewLoad}
                                        clickParams={{ resource: resource, orders: [...selectedAvailableOrders, ...selectedAssignedOrders]}} />
                                }
                                {
                                    availableSelection.length === selectedAssignedOrders.filter(as => as.rowState.allowCreateSubOrder &&
                                        as.rowState.loadData.id === selectedAssignedOrders[0].rowState.loadData.id).length && resource === 'outboundload' &&
                                    <ToolBarButton primary text="Create Sub Load"
                                        inProcess={loadArea.addNewInProcess || loadArea.createInProcess || loadArea.createSubInProcess}
                                        title='Create Sub Load'
                                        onClick={createSabload} />
                                }
                                <div className="line form_fields counts">
                                    <div className="data_label counts-tab">Space Count</div>
                                    <div className="data_read_count">
                                        <b><text purple-rose={totalSpace > 31 ? '' : null}>{totalSpace?.toLocaleString()}</text></b>
                                    </div>
                                </div>
                                <div className="line form_fields counts">
                                    <div className="data_label counts-tab">Pallet Count</div>
                                    <div className="data_read_count">
                                        <b><text purple-rose={totalPallet > 31 ? '' : null}>{totalPallet}</text></b>
                                    </div>
                                </div>
                                <div className="line form_fields counts">
                                    <div className="data_label counts-tab">Total Weight</div>
                                    <div className="data_read_count">
                                        <b><text purple-rose={totalWeight > 30000 ? '' : null}>{totalWeight?.toLocaleString()}</text></b>
                                    </div>
                                </div>
                                <ToolBarButton text="Unselect All"
                                    title='Unselect All'
                                    onClick={unreactOrders}
                                    clickParams={resource}
                                />
                            </>)
                        }
                    </action>
                </wrap>
            </div>
        </Scroll>
        {!props.isHiddenSwitch && <HideShowMenuSwitch />}
        {lumperModalIsOpen &&
            <LumperModal
                resource={resource}
                lumperModalIsOpen={lumperModalIsOpen}
                setLumperModalIsOpen={setLumperModalIsOpen}
                selectedAssignedOrders={selectedAssignedOrders}
                >
            </LumperModal>
        }
        {assignOrdersModalIsOpen && 
            <AssignOrdersModalNewLoad
                assignOrdersModalIsOpen={assignOrdersModalIsOpen}
                setAssignOrdersModalIsOpen={setAssignOrdersModalIsOpen}
                resource={resource}
                allAvailableOrders={allAvailableOrders}
                state={state}
            >
            </AssignOrdersModalNewLoad>}
        </toolbar>);
};

const mapStateToProps = state => ({
    state: state,
    screens: state.auth.profileSettings && state.auth.profileSettings.screens,
    userRole: state.auth.userRole,
});

const mapDispatchToProps = (dispatch) => ({
    load: (resource) => dispatch(load(resource)),
    openAlert: (key) => dispatch(openPopup(key)),
    saveScreenSwap: (resource, swap) => dispatch(saveScreenSwap(resource, swap)),
    unreactOrders: resource => dispatch(unreactOrders(resource)),
    assignOrdersToNewLoad: (resource, orders, index, allOrders) => dispatch(assignOrdersToNewLoad(resource, orders, 1, allOrders)),
    createSubLoadAssignOrders: (resource, orders, dropLocationId) =>
        dispatch(createSubLoadAssignOrders(resource, orders, dropLocationId)),
    addNewLoad: resource => dispatch(addNewLoad(resource)),
    openPopup: data => dispatch(openPopup(data)),
    closePopup: key => dispatch(closePopup(key)),
    closePopupAsync: key => dispatch(closePopupAsync(key))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);