import { orderBy } from "lodash";

const itemDefaultSize = 28;

export const createWebOrder = (oData, headers) => {
  const data = { ...oData };
  const loadStatus = data.loadStatus;
  const orderWebData = {
    row: [],
    rowState: {
      loadId: `load_${data.loadId}`,
      orderId: data.id,
      type: data.loadType,
      orderData: data,
      height: itemDefaultSize,
      loadStatus,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    switch (headerItem.key) {
      case 'readyDate':
        value = data.readyDate;
        fieldType = 'dateTime';
        break;
      case 'dueDate':
        value = data.dueDate;
        fieldType = 'dateTime';
        break;
      case 'pickLocation':
        value = data.pickLocation;
        fieldType = 'text';
        break;
      case 'dropLocation':
        value = data.dropLocation;
        fieldType = 'text';
        break;
      case 'poNumber':
        value = data.poNumber;
        fieldType = 'text';
        break;
      case 'soNumber':
        value = data.soNumber;
        break;
      case 'palletCount':
        value = data.palletCount;
        fieldType = 'text';
        break;
      case 'spa':
        value = data.space;
        fieldType = 'text';
        break;
      case 'lbs':
        fieldType = 'checkbox';
        value = data.weight;
        break;
        case 'temperature':
          value = data.temperature === 1 ? 'Frozen' : 
          data.temperature === 2 ? 'Chilled' :
          data.temperature === 3 ? 'Dry' :
          data.temperature === 4 ? 'C|F' : '';
          fieldType = 'text';
          break;
      // case 'freight':
      //   value = data.freight;
      //   fieldType = 'text';
      //   break;
      case 'orderCycle':
        value = 'Requested';
        break;
      case 'actions':
        fieldType = 'actions';
        value = 'button';
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      fieldType,
    });
  }
  return orderWebData;
};

const createCustomerOrdersList = (state, customerOrders) => customerOrders.map(data => createWebOrder(data, state.headers));

export const customerOrder = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  const customerOrderNewOrders = createCustomerOrdersList(state, data.data);

  return {
    ...state,
    ...{
      loading: false,
      hasError,
      message,
      customerOrderNewOrders: customerOrderNewOrders,
      totalOrders: data.total,
      orderAdded: false,
      orderUpdated: false,
    },
  };
};

export const deleteCustomerOrder = (state, responce) => {
  const { data } = responce;

  return {
    ...state,
    ...{      
      totalOrders: data?.hasError === false ? (state.totalOrders -1) : state.totalOrders 
    },
  };
};

export const addCustomerOrder = (state, responce) => {
  const { data } = responce;

  return {
    ...state,
    ...{      
      totalOrders: data.hasError ? state.totalOrders : state.totalOrders + 1,
      hasError: data.hasError,
      message: data.message ? data.message : 'Something went wrong, please try again',
      orderAdded: !data.hasError,
      addCustomerOrderloading: false,
    },
  };
};

export const editCustomerOrder = (state, responce) => {

  return {
    ...state,
    ...{      
      orderUpdated: !responce.data.hasError,
    },
  };
};

const sortByValue = (customerOrderNewOrders, value) => {
  let sortedList = [];
  if (value === 'byDate') {
    sortedList = orderBy(customerOrderNewOrders, [c => c.row[0].value], ["asc"]);
  };
  if (value === 'byDirection') {
    sortedList = orderBy(customerOrderNewOrders, [c => c.row[3].value], ["asc"]);
  };
  return sortedList;
};

export const sortBy = (state, sortValue) => ({
  ...state,
  ...{
    sortedCustomerOrderNewOrders: sortByValue(state.customerOrderNewOrders, sortValue),
  },
});

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
    },
  };
};

export const loadContent = (state, response, hasError = false, message = '') => {
  return customerOrder(state, response, hasError, message);
};

export const setError = (state, err) => ({
  ...state,
  ...{
    hasError: true,
    message: 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
    orderAdded: false,
    orderUpdated: false,
  },
});

export const resetOrderAdded = state => ({
  ...state,
  ...{
    orderAdded: false,
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});

export const setAddCustomerOrderLoading = state => ({
  ...state,
  ...{
    addCustomerOrderloading: true,
  },
});

export const setFormValues = (state, formValues) => ({
  ...state,
  ...{
    formValues: formValues,
  },
});

export const getTemperaturesList = (state, response, hasError = false, message = '') => {
  const { data } = response;

  return {
    ...state,
    ...{
      loading: false,
      hasError,
      message,
      activeTemperaturesList: data,
    },
  };
};