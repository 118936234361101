import React, {
    useState,
    useEffect,
    useRef,
    useCallback
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { connect } from "react-redux";

import axios from '../../axios/axios';
import FormScreen from '../screen/formScreen';
import Content from './content';
import { Mapping } from '../../mapping/mapping';
import { Action } from '../../config/action.config';
import { ValidateScreen } from '../../helper/validation';
import { useDelete, useDeleteReason, useCheckReference } from '../../hook/action/delete';
import { useData } from '../../hook/data/useData';
import { useMessage } from '../../hook/data/useMessage';
import { DefaultDataTypes, DefaultMessageTypes } from '../../hook/actions';
import { useFormValidation } from '../../hook/data/useFormValidation';
import errorHandler from '../../error/errorHandler';
import Loading from '../screen/loading';

import { AppPages } from '../../../project/Pages'
import { Project } from '../../../project/Project';
import { Defines, CommonLabels } from '../../../project/Defines';

import { CloneObject } from '../../helper/common';
import { updateProfile } from '../../../store/actions/authentication/authentication';
import store from '../../../store';
// import StateChanged from '../../components/browserBack';
import { openPopup } from '../../../store/actions/popup/popup';
import { PopupTypes } from '../../components/custom/popup/popup';
import {GenerateToolBars} from "../../config";

const Form = props => {
    const propsScreenKey = props.screen.Key;
    const componentRefs = useRef({});
    const history = useHistory();
    //state
    const [data, dispatch] = useData();
    const [messages, messageDispatch] = useMessage(props.screen.Form);
    const [isLoading, setLoading] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const [firstData, setFirstData] = useState();
    //end state

    const validate = useFormValidation(messageDispatch,
        data,
        Mapping,
        ValidateScreen,
        props.screen.Form,
        componentRefs);

    let { id, childId } = useParams();
    let backUrl = '';
    if (!props.showPopup) {
        backUrl = props.screen.Resource ? Project.getPageUrl(AppPages[props.screen.Resource]) : '';
    }
    const historyState = history.location.state;
    let search = '';
    if (historyState && historyState.search) {
        search = historyState.search;
    }

    const formValidation = () => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate();
        return passValidation;
    };

    const newDataValidation = (data) => {
        messageDispatch({ type: DefaultMessageTypes.START_FOCUSE, messages: true });
        const [passValidation] = validate(data);
        return passValidation;
    };

    if (props.screen.Form.DefaultId !== null && props.screen.Form.DefaultId !== undefined) {
        id = props.screen.Form.DefaultId;
    }

    const setDefaultData = useCallback(defaultData => {
        dispatch({
            type: DefaultDataTypes.INIT, data: defaultData
        });
        setFirstData(defaultData);
    }, [dispatch]);

    const loadData = useCallback((_id) => {
        if(!childId){
            _id = !_id ? id : _id;
            if (props.data) {
                setDefaultData(props.data);
                return;
            }
            if (!props.screen.Resource) {
                setLoading(false);
                return;
            }
            axios.get(
                (props.screen.NotUseIdOnLoad ? props.screen.Resource : `${props.screen.Resource}\\${(_id === Defines.DefaultCreateKey ? 0 : id)}`)
            )
                .then(response => {
                    if (response) {
                        const result = response.data;
                        if (result) {
                            if (props.screen.ParentField) {
                                result[props.screen.ParentField] = props.screen.ParentValue;
                            }

                            setDefaultData(result);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            _id = childId;
            if (props.data) {
                setDefaultData(props.data);
                return;
            }
            if (!props.screen.Resource) {
                setLoading(false);
                return;
            }
            axios.get(
                (props.screen.NotUseIdOnLoad ? props.screen.Resource : `${props.screen.Resource}\\${(_id === Defines.DefaultCreateKey ? 0 : childId)}`)
            )
                .then(response => {
                    if (response) {
                        const result = response.data;
                        if (result) {
                            if (props.screen.ParentField) {
                                result[props.screen.ParentField] = props.screen.ParentValue;
                            }

                            setDefaultData(result);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }, [childId, id, props.data, props.screen.Resource, props.screen.NotUseIdOnLoad, props.screen.ParentField, props.screen.ParentValue, setDefaultData]);

    //load data
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            loadData();
        }
        return () => { isMounted = false };
    }, [props.screen.Resource, loadData]);//props.screen.Resource, loadData
    const [customToolbar, setCustomToolbar] = useState('')
    useEffect(()=> {
        if(id){
            const detailUrl = Project.getPageUrl(AppPages[props.screen.Resource + Defines.DetailsComponentSufix]);
            if(detailUrl === '/loadplanning/locationdetails' && id && id !== Defines.DefaultCreateKey && !childId){
                const toolBars = GenerateToolBars(["default", "Clone"])
                setCustomToolbar(toolBars)
            } else if(childId){
                const toolBars = GenerateToolBars(["SaveBack", "SaveNew", "Save"])
                setCustomToolbar(toolBars)
            } else {
                setCustomToolbar('')
                setCustomToolbar(props.screen.Form.ToolBars)
            }
        }

    }, [id, childId, props.screen.Resource, props.screen.Form.ToolBars])
    //update data
    const updateData = (afterUpdateAction, newData, newResource = '') => {
        let resource = props.screen.Resource;
        if (!resource) {
            return;
        }
        if (newResource) {
            resource = newResource;
        }

        setUpdating(true);

        let method = 'PUT';
        let url = 'order/updateOrderEF';

        if (!data[propsScreenKey] || data[propsScreenKey] === 0 || childId ) {
            method = 'POST';
            url = 'order/createOrderEF';
        }

        let modifiedData = props.screen.beforeUpdate ? props.screen.beforeUpdate(newData || data, props.parentVal) : { ...(newData || data) };
        // remove assigned orders
        modifiedData.orders = modifiedData?.orders?.filter(order => !order.outboundLoadId && !order.loadId);

        if(childId){
            modifiedData.id = 0;
        }

        axios({
            url: url,
            method: method,
            data: modifiedData
        })
            .then(response => {
                // setUpdating(false);
                if (response) {
                    const result = response.data;
                    if (result) {
                        if (result.message === 'OrderHeader has been successfully added!') {
                            message.success('Order added successfully')
                        } else if (result.message === 'OrderHeader has been successfully updated!') {
                            message.success('Order updated successfully')
                        } else {
                            message.success(result.message);
                        }
                        if (resource === "User" && store.getState().auth.userId === result.id) {
                            const newState = {
                                loginName: result.loginName,
                                firstName: result.firstName,
                                lastName: result.lastName,
                                email: result.email,
                            };
                            props.updateProfile(newState);
                        }
                        if (afterUpdateAction) {
                            afterUpdateAction(result);
                        }
                    }
                }
            })
            .finally(() => {
                setUpdating(false);
            });
    };

    const deleteData = useDelete(props.screen.Title, props.screen.Resource, backUrl, search, setDeleting);
    const deleteReasonData = useDeleteReason(props.screen.Title, props.screen.ReasonConfig?.Resource, props.screen.ReasonConfig, backUrl, search, setDeleting);
    const checkReference = useCheckReference(deleteData, props.screen.Resource);

    const confirmOptions = {
        windowKey: 'wndConfirmClose',
        type: PopupTypes.Confirm,
        title: 'Are you sure?',
        text: '',
        buttonYesText: CommonLabels.Buttons.Close,
    };

    const dataAction = (action, additionalData, hasReference, subAction, disableValidation, disableCheckChanges) => {
        switch (action) {
            case Action.BeforeSave:
                if (!formValidation()) {
                    return;
                }

                if (props.beforeSaveAction) {
                    props.beforeSaveAction(data, additionalData, updateData, setDefaultData);
                }

                break;
            case Action.SaveBack:
                if ( JSON.stringify(data) === JSON.stringify(firstData) && !childId) {
                    confirmOptions.text = "You didn't make any changes. Do you really want to leave the page?";
                    confirmOptions.yesCallback = () => {
                        history.push({
                            pathname: backUrl,
                            search: search
                        });
                    }
                    props.openConfirm(confirmOptions);

                    break;
                } else {
                    if (!formValidation()) {
                        return;
                    }

                    updateData(() => {
                        history.push({
                            pathname: backUrl,
                            search: search
                        });
                    });
                }
                break;
            case Action.Clone:
                const detailUrl = Project.getPageUrl(AppPages[props.screen.Resource + Defines.DetailsComponentSufix]);
                    history.push({
                        pathname: `${detailUrl}/${Defines.DefaultCreateKey}/${id}`,
                        search: ''
                    });
                break;
            case Action.SaveNew:
                if (JSON.stringify(data) === JSON.stringify(firstData) && !childId) {
                    confirmOptions.text = "You didn't make any changes. Do you really want to leave the page and create a new one?";
                    confirmOptions.yesCallback = () => {
                        const detailUrl = Project.getPageUrl(AppPages[props.screen.Resource + Defines.DetailsComponentSufix]);
                        if (id === Defines.DefaultCreateKey) {
                            loadData(Defines.DefaultCreateKey);
                        }
                        else {
                            history.replace({
                                pathname: detailUrl + '/' + Defines.DefaultCreateKey,
                                state: {
                                    search: search
                                }
                            });
                        }
                    }
                    props.openConfirm(confirmOptions);
                    break;
                } else {
                    if (!formValidation()) {
                        return;
                    }
                    updateData(() => {
                        const detailUrl = Project.getPageUrl(AppPages[props.screen.Resource + Defines.DetailsComponentSufix]);
                        if (id === Defines.DefaultCreateKey) {
                            loadData(Defines.DefaultCreateKey);
                        }
                        else {
                            history.replace({
                                pathname: detailUrl + '/' + Defines.DefaultCreateKey,
                                state: {
                                    search: search
                                }
                            });
                        }
                    });
                }
                break;
            case Action.Save:
                // const missingDataInPriceTemplate = data?.orders?.filter(order => order.priceTemplateType > 1 && order.isTbd && (!order.weight || !order.palletCount)).length;
                const missingDataSPOT = data?.orders?.filter(order => !order.weight || !order.palletCount).length;
                // if (missingDataInPriceTemplate) {
                //     message.error("It's impossible to use the Price Template because some data is missing")
                // } else {
                    if (missingDataSPOT) {
                        data.orders.forEach(order => {
                            order.weight = order.weight ? order.weight : 0;
                            order.palletCount = order.palletCount ? order.palletCount : 0;
                        });
                    }
                    if (JSON.stringify(data) === JSON.stringify(firstData)) {
                        props.openAlert({
                            windowKey: 'wndConfirmClose',
                            type: PopupTypes.Alert,
                            title: "You didn't make any changes",
                            text: '',
                            buttonYesText: CommonLabels.Buttons.Close,
                        });
                    } else {
                        if (!formValidation()) {
                            return;
                        }
                        updateData((result) => {
                            setDefaultData(result);
                            if (props.saveAction) {
                                props.saveAction(CloneObject(result), false);
                            }
                            if ((!data[propsScreenKey] || data[propsScreenKey] === 0 || childId ) && !props.showPopup) {
                                const currentUrl = history.location.pathname;
                                const newUrl = currentUrl.replace(Defines.DefaultCreateKey, result[propsScreenKey]);
                                history.replace({ pathname: newUrl, state: { search: search } });
                            }
                        });
                    }
                // }
                break;
            case Action.SaveClose:
                if (JSON.stringify(data) === JSON.stringify(firstData)) {
                    confirmOptions.text = "You didn't make any changes. Do you really want to leave the page?";
                    confirmOptions.yesCallback = () => {
                        if (props.closeAction) {
                            props.closeAction();
                        }
                    }

                    props.openConfirm(confirmOptions);
                } else {
                    if (!formValidation()) {
                        return;
                    }
                    if (props.changeAction) {
                        setLoading(true);
                        props.changeAction(data);
                        return;
                    }
                    updateData((result) => {
                        setDefaultData(result);
                        if (props.saveAction) {
                            props.saveAction(CloneObject(result), true);
                        }
                    });
                }
                break;
            case Action.SaveSend:
                if (!formValidation()) {
                    return;
                }

                if (props.beforeSaveAction) {
                    props.beforeSaveAction(data, additionalData, updateData, setDefaultData);

                    return;
                }

                if (props.changeAction) {
                    setLoading(true);
                    props.changeAction(data);
                    return;
                }
                updateData((result) => {
                    setDefaultData(result);
                    if (props.saveAction) {
                        props.saveAction(CloneObject(result), true);
                    }
                });
                // }
                break;
            case Action.ForceUpdate:
                // additionalData is NewData
                if (JSON.stringify(additionalData) === JSON.stringify(firstData) && !disableCheckChanges) {
                    props.openAlert({
                        windowKey: 'wndConfirmClose',
                        type: PopupTypes.Alert,
                        title: "You didn't make any changes",
                        text: '',
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                } else {
                    if (!disableValidation && !newDataValidation(additionalData)) {
                        return;
                    }

                    updateData((result) => {
                        setDefaultData(result);
                        if (props.saveAction) {
                            props.saveAction(CloneObject(result), true);
                        }
                        if ((!additionalData[propsScreenKey] || additionalData[propsScreenKey] === 0 || childId) && !props.showPopup) {
                            const currentUrl = history.location.pathname;
                            const newUrl = currentUrl.replace(Defines.DefaultCreateKey, result[propsScreenKey]);
                            history.replace({ pathname: newUrl, state: { search: search } });
                        }
                    }, additionalData);
                }

                break;
            case Action.Close:
                if (JSON.stringify(data) !== JSON.stringify(firstData)) {
                    confirmOptions.text = "You made the changes and not save them. Do you really want to leave the page?";
                    confirmOptions.yesCallback = () => {
                        if (props.closeAction) {
                            props.closeAction();
                        }
                    }

                    props.openConfirm(confirmOptions);
                } else {
                    if (props.closeAction) {
                        props.closeAction();
                    } else if (backUrl) {
                        history.push({
                            pathname: backUrl,
                            search: search
                        });
                    }
                }

                break;
            case Action.Delete:
                checkReference(data[props.screen.Master], data[propsScreenKey], id, hasReference);
                //deleteData(data[props.screen.Master], data[propsScreenKey]);
                break;
            case Action.CancelReason:
                deleteReasonData(data[props.screen.Master], data[propsScreenKey], data);
                break;
            case Action.ChangePassword:
                if (props.forgotAction) {
                    props.forgotAction();
                }
                break;
            case Action.Custom:
                if (!formValidation()) {
                    return;
                }
                props.screen.Form.CustomAction ?
                    props.screen.Form.CustomAction(subAction, dispatch, dataAction, data) :
                    console.warn('Custom Action Not Defined');
                break;
            case Action.CustomWithoutValidate:
                props.screen.Form.CustomAction ?
                    props.screen.Form.CustomAction(subAction, dispatch, dataAction, data) :
                    console.warn('Custom Action Not Defined');
                break;
            default:
                break;
        }
    };

    const handleBack = (e) => {
        if (JSON.stringify(data) !== JSON.stringify(firstData)) {
            e.preventDefault();

            confirmOptions.text = "You made the changes and not save them. Do you really want to leave the page?";
            confirmOptions.yesCallback = () => {
                history.push({
                    pathname: backUrl,
                    search: search
                });
            }
            props.openConfirm(confirmOptions);
        }
    }

    const getTitle = () => {
        let titleSuffix = '';
        let titlePrefix = 'New ';
        if (data && data[propsScreenKey]) {
            titlePrefix = '';
            titleSuffix = data[props.screen.Master] ? ` ${data[props.screen.Master]}` : '';
        }
        return `${titlePrefix}${props.screen.Title}${titleSuffix}`;
    };
    if (!data && isLoading) {
        return <Loading />;
    }

    return (
        <FormScreen
            showPopup={props.showPopup}
            backUrl={backUrl}
            search={search}
            title={getTitle()}
            isLoading={isLoading || isUpdating || isDeleting}
            handleBack={handleBack}
            onCancel={() => dataAction(Action.Close)}
        >
            <form >
                <Content
                    config={{...props.screen.Form, ToolBars: customToolbar ? customToolbar : props.screen.Form.ToolBars}}
                    data={data}
                    dispatch={dispatch}
                    messages={messages}
                    messageDispatch={messageDispatch}
                    componentRefs={componentRefs}
                    dataAction={dataAction}
                />
            </form>
        </FormScreen>);
};
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (data) => dispatch(updateProfile(data)),
        openConfirm: (data) => dispatch(openPopup(data)),
        openAlert: (data) => dispatch(openPopup(data))
    };
};

export default connect(null, mapDispatchToProps)(errorHandler(Form, axios));