import React from 'react';
import { useDispatch } from "react-redux";
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import axios from 'axios';
import store from '../../../../store';
import { reCalculatePrice, setPriceAsCalculated } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';

const PriceRecalculationModal = ({ 
    priceRecalculationModalIsOpen,
    setPriceRecalculationModalIsOpen,
    rowState,
    resource,
  }) => {

  const dispatch = useDispatch();

  const closeModal = () => {
    setPriceRecalculationModalIsOpen(false);
  };

  const customStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '300px',
      maxWidth: '320px',
      minHeight: '180px',      
      borderRadius: '8px',
      padding: '6px 12px',
      textAlign: 'center',
    },
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? 'lightGray' : 'lightGray',
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '37px',
      width: '100%',
      cursor: 'notAllowed',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: '20',
    }),
    menuList: () => ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderRadius: 'none!important',
    }),
    valueContainer: (base, state) => ({
      display: 'flex',
      alignItems: 'self-end',
      paddingLeft: '10px',
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    container: (base, state) => ({
      ...base,
      pointerEvents: 'auto',
    }),
    singleValue: (base, state) => ({
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '4px 0',
    }),
    options: (base, state) => ({
      padding: '4px 8px',
      cursor: 'pointer',
      borderRadius: 'unset',
    }),
  };

  const validationSchema = Yup.object().shape({
    priceTemplateType: Yup.string().required('Required'),
    priceTemplateTypeId: Yup.number().required('Required'),
    price: Yup.number().required('Required'),
    priceTemplateId: Yup.number().required('Required'),
  });

  const handleSubmitSave = values => {
    dispatch(reCalculatePrice(resource, rowState.orderData.id, values, 'orderschangerequestslist'));
    dispatch(setPriceAsCalculated(resource, rowState.orderData.id));
  };

  const axiosConfig = () => ({
      headers: {
          'Authorization': 'Bearer ' + store.getState().auth.userToken
      }
  });
  const buildPriceUrl = endpoint => `/api/${endpoint}`;

  const setResponceData = (responce, setFieldValue) => {
    setFieldValue('price', responce.price);
    setFieldValue('priceTemplateId', responce.priceTemplateId);
    setFieldValue('priceTemplateType', 'Price Template');
    setFieldValue('priceTemplateTypeId', responce.priceTemplateType);
  };

  const updatePriceByTemplate = (customerId, pickLockId, dropLockId, palletCount, weight, setFieldValue) => {
      const filterData  = {
          params: {
              customerId: customerId,
              pickLockId: pickLockId,
              dropLockId: dropLockId,
              palletCount: palletCount,
              weight: weight,
              priceTemplateTypeId: 2,

          },
          headers: axiosConfig().headers,
      };
      axios.get(buildPriceUrl(`Order/pricebytemplate`), filterData, axiosConfig())
          .then(responce => setResponceData(responce.data, setFieldValue))
      };

  const handleSetFieldValue = (value, setFieldValue) => {
    if (value === 'Price Template') {
      const { customerId, pickLocationId, dropLocationId, palletCount, weight } = rowState.orderData; 
      updatePriceByTemplate(customerId, pickLocationId, dropLocationId, palletCount, weight, setFieldValue);
    } else {
      setFieldValue('priceTemplateType', 'Spot');
      setFieldValue('priceTemplateTypeId', 1);
      setFieldValue('priceTemplateId', 0);
      setFieldValue('price', '');
    }
  }

  let options = [
    {
      value: 'Spot', 
      label: 'Spot',
    },
    {
      value: 'Price Template', 
      label: 'Price Template',
    }
  ];
  if (rowState.orderData.batch === 1) {
      options = [ { value: 'Spot', label: 'Spot' } ];
  };

  return (
    <Modal isOpen={priceRecalculationModalIsOpen} onRequestClose={closeModal} style={customStyle} ariaHideApp={false}>
      <div className='comment_modal__container'>
        <div className='price-recalculation_modal__title-container'>
          <div>Select Price option</div>
          <button onClick={closeModal} className='price-recalculation-modal_x-btn'>X</button>
        </div>
        <Formik
          initialValues={{
            priceTemplateType: '',
            price: '',
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleSubmitSave(values);
            }, 400);
          }}>
          {({ isValid, values, setFieldValue, errors, touched, handleSubmit }) => {
            return (
              <Form style={{ width: '86%' }}>
                <div className='modal_formik_container'>
                  <div className='load-efs__select-container'>
                    {!values.priceTemplateType && <div className='select-price__title'>Select Price option</div>}
                    <Select
                      options={options}
                      name='priceTemplateType'
                      value={{ label: values.priceTemplateType }}
                      className={`${touched.priceTemplateType && errors.priceTemplateType ? 'dropdown_error' : ''} `}
                      onChange={option => handleSetFieldValue(option.value, setFieldValue)}
                      styles={customStyles}
                      placeholder={''}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>

                  <div className={'price-recalculation__input-container'} style={touched.price && errors.price && { border: '1px solid red' } }>
                    <span style={{ display: 'flex' }}>$
                      <Field
                        name="price"
                        className='price-recalculation__input'
                        type="number"
                        disabled={values.priceTemplateType === 'Price Template'}
                      />
                    </span>
                  </div>

                  <div className='price-recalculation_button__container'>
                    <button 
                        className={`price-recalculation__btn ${values.price === undefined || values.price < 0 || !values.priceTemplateType || errors.price ? 'price-btn-disabled' : ''} `}
                        type='submit' 
                        onClick={handleSubmit}
                        disabled={values.price === undefined || values.price < 0 || !values.priceTemplateType || errors.price}>
                      OK
                    </button>
                    <button 
                        className='price-recalculation__btn price-recalculation__btn__cancel' 
                        type='submit' 
                        onClick={closeModal}>
                      CANCEL
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default PriceRecalculationModal;